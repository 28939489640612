.react-datepicker {
  font-family: unset;
  display: flex;
  align-content: center;
  border: 0px;
  font-size: 0.9rem;
  border: none;
  background: none;
  color: inherit;
}

.react-datepicker__header {
  background-color: transparent;
  border: 0px;
}

.react-datepicker__year {
  text-align: unset;
}

.react-datepicker__year--container,
.react-datepicker__month-container,
.react-datepicker__time-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.react-datepicker__month {
  background-color: var(--monthBackground);
  margin: 100;
  padding: 0.4rem;
}

.react-datepicker__day--disabled {
  opacity: 0.3;
}

.react-datepicker__day--outside-month {
  pointer-events: none;
  opacity: 0.1;
}

.react-datepicker__header--time {
  display: none;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: inherit;
  background-color: transparent;
  font-size: inherit;
  font-weight: 600;
}

.react-datepicker__time-container {
  border: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: transparent;
}

/*Containers inside datepicker__header and datepicker__month*/
.react-datepicker__year-wrapper {
  justify-content: center;
  margin: 0 auto;
}

.react-datepicker__year-text {
  width: 3rem !important; /* this limits modularity...*/
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__header > div:nth-child(1), /*chakra buttons on top of the header*/
.react-datepicker__day-names,
.react-datepicker__week,
.react-datepicker__month-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/*This does not work. How to make te timelist stretch to the bottom of the Pop as it changes size when going through months?*/
/* .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  align-self: stretch;
} */

/*Time picker*/
.react-datepicker.react-datepicker--time-only,
.react-datepicker.react-datepicker--time-only .react-datepicker__time-container,
.react-datepicker.react-datepicker--time-only .react-datepicker__time-box,
.react-datepicker.react-datepicker .react-datepicker__time-box {
  margin: 0 0;
  width: 100%;
  height: 100%;
}

/*Items in the time list*/
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none;
}

/*Hover over unselected items*/
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover,
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__year-text:hover {
  background: #96afc6;
}

.react-datepicker__day,
.react-datepicker__day-name {
  color: inherit;
}

/*Today*/
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  border: solid 1px #4895de;
  border-radius: 0.3rem;
}

/*Selections*/
.react-datepicker__day--selected,
.react-datepicker__day--highlighted,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month--selected,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #205e9f; /*blue600*/
  font-weight: normal;
  color: whitesmoke;
}

/*Hovering over selections*/
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover,
.react-datepicker__day--selected:hover,
.react-datepicker__day--highlighted:hover,
.react-datepicker__month--selected.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__year-text--selected:hover {
  background: #4895de;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__day--outside-month:hover {
  background: transparent;
}

/*
react-datepicker__month 
react-datepicker__month react-datepicker__yearPicker
*/

/* 
.light-theme{
  --light-gray: var(--chakra-colors-gray-200);
  --gray: var(--chakra-colors-gray-300);
  --blue700:var(--chakra-colors-blue-600);
  --blue600: var(--chakra-colors-blue-500);
  --blue500: var(--chakra-colors-gray-400);
  --blue400: var(--chakra-colors-gray-300);
  --blue300: var(--chakra-colors-gray-200);
  --blue200: var(--chakra-colors-gray-200);
  --blue100: var(--chakra-colors-gray-100);
  --monthBackground: var(--chakra-colors-white);
  --text:var(--chakra-colors-black);
  --negative-text:var(--chakra-colors-white);
}
.dark-theme{
  --light-gray: var(--chakra-colors-gray-600);
  --gray: var(--chakra-colors-gray-500);
  --blue700: var(--chakra-colors-blue-600);
  --blue600: var(--chakra-colors-blue-300);
  --blue500: var(--chakra-colors-gray-500);
  --blue400: var(--chakra-colors-gray-600);
  --blue300: var(--chakra-colors-gray-700);
  --blue200: var(--chakra-colors-gray-600);
  --blue100: var(--chakra-colors-gray-800);
  --monthBackground: var(--chakra-colors-gray-700);
  --text:var(--chakra-colors-gray-200);
  --negative-text:var(--chakra-colors-black);
}

// if you dont want to use chakra's theme use this class in the wrapping div. These are the exact original values
.light-theme-original{
  --light-gray: #cccccc;
  --gray: #b3b3b3;
  --blue700:#2a69ac;
  --blue600: #3182ce;
  --blue500: #a0aec0;
  --blue400: #cbd5e0;
  --blue300: #e2e8f0;
  --blue200: #edf2f7;
  --blue100: #f7fafc;
} 

.react-datepicker-popper {
  z-index: 5;
}

.react-datepicker__input-container {
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: 1px solid;
  border-color: var(--light-gray);
}
.react-datapicker__input-text {
  background-color: transparent;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__input-container:hover {
border-color: var(--gray)
}
.react-datepicker__input-container:focus-within {
z-index: 1;
border-color: var(--blue600);
box-shadow: 0 0 0 1px var(--blue600);
}

.react-datepicker__input-container > input {
width: 100%;
height: 100%;
outline: 0;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 8px;
}

.react-datepicker__navigation--previous {
  border-right-color: var(--blue400);
}

.react-datepicker__navigation--previous:hover {
  border-right-color: var(--blue500);
}

.react-datepicker__navigation--next {
  border-left-color: var(--blue400);
}

.react-datepicker__navigation--next:hover {
  border-left-color: var(--blue500);
}

.react-datepicker__close-icon::after {
  background-color: unset;
  border-radius: unset;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--light-gray);
  height: 20px;
  width: 20px;
}

.react-datepicker__close-icon::after:hover {
  color: var(--gray);
}

*/
